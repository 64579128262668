import sushi from '../assets/sushi.png'
import lawyer from '../assets/lawyer.png'
import premiumui from '../assets/premiumuikit.png'
import aianalytics from '../assets/aianalytics.png'
import portfolio from '../assets/portfolio.png'
import nature from '../assets/nature.png'
import aahandyman from '../assets/aahandyyman.png'
const projects = [
    {img: sushi, link: 'https://www.freshsushibobatea.com/'},
    {img: lawyer, link: 'https://americanlegal.vercel.app/'},
    {img: premiumui, link: 'https://pageflows.vercel.app/'},
    {img: portfolio, link: 'https://businessone.vercel.app/'},
    {img: aianalytics, link: 'https://analyticai.vercel.app/?last_month_sales_volume_min=0&last_month_sales_volume_max=50000'},
    {img: nature, link: 'https://photography-xi-red.vercel.app/'},
    {img: aahandyman, link: 'https://aahandyman.vercel.app/'},
]

export default projects