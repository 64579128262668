import React from 'react';
import projects from '../components/project';
import { Helmet } from 'react-helmet-async';

export const MoreWork = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="This is my project page that shows what different projects I finished for different clients in Web Development."
        />
      </Helmet>
      <h1 className="text-4xl font-bold text-center mb-12 text-gray-800 dark:text-gray-200">
        My Projects
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <div
            key={index}
            className="group relative overflow-hidden rounded-lg shadow-lg bg-white dark:bg-gray-800 hover:shadow-2xl transition-shadow duration-300"
          >
            {/* Project Image */}
            <img
              src={project.img}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover transition-transform duration-300 transform group-hover:scale-105"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end">
              <div className="p-4">
                <p className="text-white text-xl font-bold truncate mb-2">
                  {project.title || `Project ${index + 1}`}
                </p>
                <p className="text-gray-100 text-base mb-4">
                  {project.description || 'Click below to visit the site.'}
                </p>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block text-center text-sm text-white font-semibold bg-orange-500 px-6 py-2 rounded-full hover:bg-orange-600 transition-all duration-300"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
